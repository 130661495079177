// Configuration
@import "../../../../public/scss/bootstrap/v5/functions";
@import "../../../../public/scss/bootstrap/v5/variables";
@import "../../../../public/scss/bootstrap/v5/variables-dark";
@import "../../../../public/scss/bootstrap/v5/maps";
@import "../../../../public/scss/bootstrap/v5/mixins";
@import "../../../../public/scss/bootstrap/v5/utilities";

// Layout & components
@import "../../../../public/scss/bootstrap/v5/root";
@import "../../../../public/scss/bootstrap/v5/reboot";
@import "../../../../public/scss/bootstrap/v5/type";
@import "../../../../public/scss/bootstrap/v5/images";
@import "../../../../public/scss/bootstrap/v5/containers";
@import "../../../../public/scss/bootstrap/v5/grid";
@import "../../../../public/scss/bootstrap/v5/tables";
@import "../../../../public/scss/bootstrap/v5/forms";
@import "../../../../public/scss/bootstrap/v5/buttons";
@import "../../../../public/scss/bootstrap/v5/transitions";
@import "../../../../public/scss/bootstrap/v5/dropdown";
@import "../../../../public/scss/bootstrap/v5/button-group";
@import "../../../../public/scss/bootstrap/v5/nav";
@import "../../../../public/scss/bootstrap/v5/navbar";
@import "../../../../public/scss/bootstrap/v5/card";
@import "../../../../public/scss/bootstrap/v5/accordion";
@import "../../../../public/scss/bootstrap/v5/breadcrumb";
@import "../../../../public/scss/bootstrap/v5/pagination";
@import "../../../../public/scss/bootstrap/v5/badge";
@import "../../../../public/scss/bootstrap/v5/alert";
@import "../../../../public/scss/bootstrap/v5/progress";
@import "../../../../public/scss/bootstrap/v5/list-group";
@import "../../../../public/scss/bootstrap/v5/close";
@import "../../../../public/scss/bootstrap/v5/toasts";
@import "../../../../public/scss/bootstrap/v5/modal";
@import "../../../../public/scss/bootstrap/v5/tooltip";
@import "../../../../public/scss/bootstrap/v5/popover";
@import "../../../../public/scss/bootstrap/v5/carousel";
@import "../../../../public/scss/bootstrap/v5/spinners";
@import "../../../../public/scss/bootstrap/v5/offcanvas";
@import "../../../../public/scss/bootstrap/v5/placeholders";

// Helpers
@import "../../../../public/scss/bootstrap/v5/helpers";

// Utilities
$widths: (
    33: 33.333333%,
    66: 66.666667%,
);
$utilities: map-merge(
        $utilities,
        (
            "width": map-merge(
                    map-get($utilities, "width"),
                    (
                        values: map-merge(
                                map-get(map-get($utilities, "width"), "values"),
                                $widths,
                        ),
                    ),
            ),
        )
);
@import "../../../../public/scss/bootstrap/v5/utilities/api";

// Custom styles
.dropdown {
    &-toggle {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0 4px;

        &::after {
            margin-left: 0;
            width: 24px;
            height: 24px;
            border: 0;
            background: {
                color: transparent;
                image: url(/bundles/hitcomfrontend/img/material-design-icons/outlined/expand_more.svg);
                position: 0 0;
                repeat: no-repeat;
                size: 100% 100%;
            }
            transition: transform .2s ease-in-out;
        }

        &.show {
            &::after {
                transform: rotate(-180deg);
            }
        }
    }

    &-menu {
        min-width: 0;
        border: 1px solid var(--color-gray, $color__gray);
        border-radius: 4px;
    }

    &-item {
        padding: 4px 8px;

        &.active,
        &:active,
        &:hover,
        &:focus {
            color: var(--color-font, $color__font);
            background-color: var(--color-gray-light, $color__gray--light);
        }
    }
}
